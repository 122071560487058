import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { CustumedMatrix } from "tamtam-components";
import { getCollaboratorContribution } from "../../../../api";
import _ from "../../../../i18n";
import { Tooltip as TooltipAntd } from "antd";
import { ArrowLeftIcon, FlagIcon, ShortcutIcon } from "../../../common/Icons";
import "./EncodingProgressionContent.local.scss";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { renderDurationDisplay } from "../../../../utils";
import { Link } from "react-router-dom";

const ENCODING_TYPE = [
  "sales",
  "remuneration",
  "purchases",
  "financial_transactions_coda",
  "financial_transactions_noncoda",
  "caisse",
];

const NonFacturableContent = ({
  id,
  year,
  lng,
  name,
  token,
  user,
  filter,
  loggedAs,
  collaborator,
  organization,
}) => {
  const [expandedClient, setExpandedClient] = useState(null);
  const [showSideBar, setShowSideBar] = useState(null);
  const [progression, setProgression] = useState([]);
  const [contribution, setContribution] = useState([]);
  const [filtredContribution, setFiltredContribution] = useState([]);
  const [filtredProgression, setFiltredProgression] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortApplication, setSortApplication] = useState(null);
  const [columns, setColumns] = useState([
    {
      id: "volumetry",
      label: _("volumetry"),
      isEditable: true,
      widthPercentage: 10,
      // filter.period && "MONTHLY" !== filter.period.id ? 20 : 10,
    },
    {
      id: "timesheet",
      label: _("timesheet"),
      isEditable: true,
      widthPercentage: 50,
    },
  ]);

  let collaboratorContributionQuery = useQuery(
    ["collaboratorContributionQuery", token, year, filter],
    async () => {
      if (token) {
        try {
          setIsLoading(true);
          const response = await getCollaboratorContribution(
            id,
            organization,
            year,
            token,
            [filter.category.id],
            filter.period && filter.period.id,
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.start
                : filter.subPeriod.start
              : filter.start
                ? moment(filter.start).format("MM-DD")
                : "01-01",
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.end
                : filter.subPeriod.end
              : filter.end
                ? moment(filter.end).format("MM-DD")
                : "12-31",
            filter.keyWord,
            filter.category.label.split(".")[0],
          );
          setContribution(response.data.data.clientsData);
          setFiltredContribution(response.data.data.clientsData);
          setIsLoading(false);
        } catch (e) {
          setContribution([]);
          return null;
        }
      }
    },
  );

  const renderPannelFrame = () => {
    return (
      <div className="production-frame">
        {collaboratorContributionQuery.isFetching ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : !collaboratorContributionQuery.isFetching &&
          filtredContribution &&
          filtredContribution.length === 0 ? (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("noCollaboratorProductionFound")}</div>
          </div>
        ) : (
          collaboratorContributionQuery.isFetched && 
            <div className="production-frame_scroll">
              <div className="total">
                {_("folderCount")} : {contribution.length - 1}
              </div>
              <CustumedMatrix
                columns={columns}
                items={filtredContribution}
                handleCellule={handleColumns}
                handleTitle={handleTitle}
                headerTagBG={"#FFFFFF"}
              />
            </div>
        )}
      </div>
    );
  };

  const prepareExpandedClient = (id) => {
    if (typeof id === "string" && id.split("-").length > 1) {
      return;
    }
    if (id === "TOTAL") {
      return;
    }
    if (id === expandedClient) {
      const boxes = document.querySelectorAll(
        ".CustumedMatrix_matrix_body_line__11lbb"
      );

      boxes.forEach((box) => {
        box.classList.remove("sub_line");
        box.classList.remove("last_sub_line");
        box.classList.remove("expanded_line");
      });
      setFiltredContribution(contribution);
      setFiltredProgression(progression.global);
      setExpandedClient(null);
    } else {
      let result = [];
      contribution.forEach((item) => {
        result.push(item);
        if (item.id === id) {
          Object.keys(item.children).forEach((child) => {
            result.push(item.children[child]);
          });
        }
      });
      setExpandedClient(id === expandedClient ? null : id);
      setFiltredProgression(progression[id]);
      setFiltredContribution(result);
      if (document.getElementById("expanded")) {
        setTimeout(() => {
          document
            .getElementById("expanded")
            .parentElement.parentElement.classList.add("expanded_line");
          document
            .getElementById("sub-line-sales")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-purchases")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-financial_transactions_coda")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-financial_transactions_noncoda")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-caisse")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-remuneration")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-remuneration")
            .parentElement.parentElement.classList.add("last_sub_line");
        }, "100");
      }
    }
  };

  const sortClientBy = (type) => {
    let sortedData = [...filtredContribution].filter((a) => a.id !== "TOTAL");
    let totalData = filtredContribution.filter((a) => a.id === "TOTAL")[0];
    switch (type) {
      case "ecart_asc":
        sortedData.sort((a, b) => {
          return a.timesheet.ecart > b.timesheet.ecart ? -1 : 1;
        });
        sortedData.unshift(totalData);
        setFiltredContribution(sortedData);
        return;
      case "ecart_desc":
        sortedData.sort((a, b) => {
          return a.timesheet.ecart < b.timesheet.ecart ? -1 : 1;
        });
        sortedData.unshift(totalData);
        setFiltredContribution(sortedData);
        return;
      default:
        return;
    }
  };


  const handleColumns = (column, line) => {
    if (line.title.label === "total") {
      if (column.id === "volumetry") {
        return (
          <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
            <div className="labled-tag no-border">
              <span>{_("realized")}</span>
              <div className="tag">
                <div>{line.volumetry.progression.real} p</div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className="tags"
            style={{ margin: "20px 10px 10px 10px" }}
            onClick={() => prepareExpandedClient(line.id)}
          >
            {Object.keys(line.timesheet).map((key) => {
              return (
                <div className="labled-tag">
                  <span>
                    {_(key)}
                    {"ecart" === key && (
                      <div
                        style={{
                          padding: "0px",
                          height: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!sortApplication) {
                            sortClientBy("ecart_asc");
                            setSortApplication("ecart_asc");
                          } else {
                            sortClientBy(
                              sortApplication === "ecart_desc"
                                ? "ecart_asc"
                                : "ecart_desc"
                            );
                            setSortApplication(
                              sortApplication === "ecart_desc"
                                ? "ecart_asc"
                                : "ecart_desc"
                            );
                          }
                        }}
                      >
                        {sortApplication === "ecart_desc" ? (
                          <TiArrowSortedUp />
                        ) : (
                          <TiArrowSortedDown />
                        )}
                      </div>
                    )}
                  </span>
                  <div
                    className={`tag ${
                      "ecart" === key &&
                      (line.timesheet[key] <= 0 ? "tag-success" : "tag-danger")
                    }`}
                  >
                    <div>
                      {"ecart" === key && (line.timesheet[key] > 0 ? "+" : "-")}
                      {ENCODING_TYPE.includes(line.title.label) && key === "pc"
                        ? "-"
                        : key === "pc"
                        ? line.timesheet[key] + "%"
                        : renderDurationDisplay(line.timesheet[key])}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    } else {
      if (column.id === "volumetry") {
        return (
          <Fragment>
            <div
              className="tags"
              onClick={() => prepareExpandedClient(line.id)}
            >
              <div className="tag no-border">
                <div>{line.volumetry.progression.real} p</div>
              </div>
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <div
              className="tags"
              onClick={() => prepareExpandedClient(line.id)}
            >
              {Object.keys(line.timesheet).map((key) => {
                return (
                  <div
                    className={`tag ${
                      "ecart" === key &&
                      (line.timesheet[key] <= 0 ? "tag-success" : "tag-danger")
                    }`}
                  >
                    <div
                      style={{
                        color:
                          key === "pc" && line.timesheet[key] > 15
                            ? "#DE4848"
                            : "#6D7F92",
                      }}
                    >
                      {"ecart" === key && (line.timesheet[key] > 0 ? "+" : "-")}
                      {ENCODING_TYPE.includes(line.title.label) && key === "pc"
                        ? "-"
                        : key === "pc"
                        ? line.timesheet[key] + "%"
                        : renderDurationDisplay(line.timesheet[key])}
                    </div>
                    {line.title.softwareCalcul === "default" &&
                      key === "theoric" && (
                        <TooltipAntd
                          key={`tab-20}`}
                          placement="top"
                          title={_(
                            line.title.softwareCalcul === line.title.software
                              ? `defaultSoftwareMessage`
                              : `noSoftwareCoefficientsMessage`
                          )}
                        >
                          <div className="notif-pop" />
                        </TooltipAntd>
                      )}
                  </div>
                );
              })}
            </div>
          </Fragment>
        );
      }
    }
  };

  const handleTitle = (title) => {
    return (
      <Fragment>
        <div
          onClick={() => prepareExpandedClient(title.id)}
          className={`${
            title.title.label === "total"
              ? "matrix_title_header"
              : ENCODING_TYPE.includes(title.title.label)
              ? "matrix_sub_title"
              : "matrix_title"
          }`}
          id={
            title.title.label === "total"
              ? "total-line"
              : expandedClient === title.id
              ? "expanded"
              : ENCODING_TYPE.includes(title.title.label)
              ? `sub-line-${title.title.label}`
              : ""
          }
        >
          <div className="title_infos">
            {title.title.label !== "total" &&
              !ENCODING_TYPE.includes(title.title.label) && (
                <div className={title.title.state}>
                  <ArrowLeftIcon width={9} height={7} fill="#FFFFFF" />
                </div>
              )}
            <div>
              {ENCODING_TYPE.includes(title.title.label)
                ? _(title.title.label)
                : title.title.label}
            </div>
          </div>

          {title.title.label !== "total" &&
            !ENCODING_TYPE.includes(title.title.label) &&
            collaborator &&
            !collaborator.isDeleted && (
              <div className="actions">
                <Link to={`/${lng}/fiche-client/${title.id}`}>
                  <div className={`title_action action_doh`}>
                    <ShortcutIcon size="16" />
                  </div>
                </Link>
                <div
                  className={`title_action ${
                    title.title.state !== "danger" && title.title.count === 0
                      ? "action_doh"
                      : ""
                  }`}
                  style={{ width: title.title.count > 0 ? "" : "51%" }}
                  onClick={() => setShowSideBar(title)}
                >
                  <FlagIcon />
                  <div>{title.title.count > 0 ? title.title.count : "+"}</div>
                </div>
              </div>
            )}
        </div>
      </Fragment>
    );
  };
  return (
    <div className="client-content">
      <div className="content-backscroll" style={{ height: "100%" }}>
        <div className="CustumedMatrix_header_scroll">
          {renderPannelFrame()}
        </div>
      </div>
    </div>
  );
};

export default NonFacturableContent;
