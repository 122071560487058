import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Outlet, Link, useLocation } from "react-router-dom";
// import { SubMenu } from "tamtam-components";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import {
  setAuthUser,
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
  setAuthLoggedAs,
  setAuthLoggedAsPost,
  setAuthSpaceAs,
  fetchAuthUser,
  setLanguage,
  setNavCommunity,
  setNavCommunityId,
  fetchNavCommunity,
  setAuthLoggedIsAdmin,
  setManagerNotification,
  setSynchronisationNotification,
  setHeader,
} from "../../store";
import {
  getAuthInfosFromCookie,
  getLocalStorageAuth,
  setLocalStorageAuth,
  authHasExpired,
  getCookie,
  logout,
  getUserCurrentNavCommunity,
} from "../../utils";
import {
  getClientCredential,
  getManagerNotification,
  getCollaboratorsTimeSheetNotification,
} from "../../api";
import {
  APP_ENV,
  TTP_POWERTEAM_URL,
  TTP_HOME_URL,
  URL_HASH_KEY,
} from "../../config";
import _ from "../../i18n";

import Menu from "./Menu";
import SubMenu from "./SubMenu";

const decryptWithAES = (ciphertext) => {
  var encryptMethod = "AES-256-CBC";
  var aesNumber = encryptMethod.match(/\d+/)[0];
  var encryptMethodLength = parseInt(aesNumber);

  var json = JSON.parse(
    CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(ciphertext))
  );

  var salt = CryptoJS.enc.Hex.parse(json.salt);
  var iv = CryptoJS.enc.Hex.parse(json.iv);

  var encrypted = json.ciphertext;

  var iterations = parseInt(json.iterations);
  if (iterations <= 0) {
    iterations = 999;
  }
  var encryptMethodLength = encryptMethodLength / 4;
  var hashKey = CryptoJS.PBKDF2(URL_HASH_KEY, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations,
  });

  var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

const Layout = () => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const { loggedAs, spaceAs } = auth;
  const language = useSelector((state) => state.params.language);
  const showHeader = useSelector((state) => state.params.header);
  const [synchroNotificationFetched, setSynchroNotificationFetched] =
    useState(false);
  const dispatch = useDispatch();
  let { lang } = useParams();
  const navigate = useNavigate();
  const [preferences, setPreferences] = useState(null);

  const handleAuthUser = (user) => {
    dispatch(setAuthToken(user.token));
    dispatch(setExpiresIn(user.expiresIn));
    dispatch(setTokenCreatedAt(user.createdAt));
    dispatch(fetchAuthUser(user));
    // dispatch(fetchFiduciary());
  };

  useEffect(() => {
    var query = location.search.substring(1);
    var vars = query.split("&");
    const searchParams = new URLSearchParams(location.search);

    let queryToken = "";
    let userId = "";
    let organisationId = "";

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "params") {
        const hashParams = decryptWithAES(decodeURIComponent(pair[1]));

        const tabQueryParams = hashParams.split(",");
        tabQueryParams.forEach((item) => {
          const tmp = item.split("=");
          if (tmp.length > 0) {
            if (tmp[0] === "token") {
              queryToken = tmp[1];
            } else if (tmp[0] === "userId") {
              userId = tmp[1];
            } else if (tmp[0] === "without_header") {
              dispatch(setHeader(false));
              // } else if (tmp[0] === "without_submenu") {
              //   dispatch(setSubMenu(false));
              // } else if (tmp[0] === "without_backnav") {
              //   dispatch(setBackNav(false));
              // } else if (tmp[0] === "current_app") {
              //   dispatch(setCurrentApp(tmp[1]));
            } else if (tmp[0] === "organization_id") {
              dispatch(setNavCommunityId(tmp[1]));
            }
          }
        });
      }
    }

    let authInfos = getAuthInfosFromCookie();

    if (["fr", "en", "nl"].includes(lang)) {
      dispatch(setLanguage(lang));
      // cookieCutter.set(`ttp_lng_${APP_ENV}`, lng);
    } else {
      const lng = localStorage.getItem("lng") || "fr";
      dispatch(setLanguage(lng));
    }

    const auth = getLocalStorageAuth();

    if (queryToken && userId) {
      handleAuthUser({
        token: queryToken,
        createdAt: Date.now(),
        expiresIn: 10000,
        id: userId,
      });
    } else if (auth && auth.user && authInfos.token === auth.token) {
      let collaboratorListSynchroList = [];
      if (auth.navCommunity) {
        collaboratorListSynchroList = getCollaboratorsTimeSheetNotification(
          auth.navCommunity.id,
          auth.token
        );
      }

      dispatch(setAuthToken(auth.token));
      dispatch(setExpiresIn(auth.expiresIn));
      dispatch(setTokenCreatedAt(auth.createdAt));
      dispatch(setAuthUser(auth.user));
      dispatch(setAuthLoggedAs(auth.loggedAs));
      dispatch(setAuthLoggedAsPost(auth.loggedAsPost));
      if (collaboratorListSynchroList.length > 0) {
        collaboratorListSynchroList.then((result) => {
          const data = result.data.data;
          dispatch(setSynchronisationNotification(data));
        });
      }

      if (
        auth.user.role &&
        ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(auth.user.role.type) &&
        auth.user.role.typeStatus !== "FOLDER"
      ) {
        dispatch(setAuthLoggedIsAdmin(true));
      }
      if (auth.navCommunity) {
        dispatch(setNavCommunity(auth.navCommunity));
      }

      const lng = localStorage.getItem("lng");
      if (!lng || lng.length === 0) {
        dispatch(setLanguage(auth.user.language));
      }

      if (!auth.navCommunity) {
        dispatch(fetchNavCommunity());
      }
    } else if (!authInfos || authHasExpired(authInfos)) {
      setLocalStorageAuth(null);
      const goto = encodeURIComponent(TTP_POWERTEAM_URL);
      window.location.assign(`${TTP_HOME_URL}?goto=${goto}`);
    } else if (authInfos) {
      handleAuthUser(authInfos);
      window.location.assign(`/`);
    } else {
      const tokenData = getClientCredential();
      if (tokenData?.responseJSON?.token) {
        const savedToken = tokenData?.responseJSON.token;
        dispatch(setAuthToken(savedToken.access_token));
        dispatch(setExpiresIn(savedToken.expiresIn));
        dispatch(setTokenCreatedAt(savedToken.createdAt));
        // dispatch(setAuthUser(auth.user));
      }
    }
  }, []);

  useEffect(() => {
    if (auth.user && !auth.navCommunity) {
      const cookieCommunity = getCookie("ttp_community_" + APP_ENV);
      if (cookieCommunity) {
        let currentNavCommunity = getUserCurrentNavCommunity(
          auth.user,
          cookieCommunity
        );
        if (currentNavCommunity) {
          dispatch(setNavCommunity(currentNavCommunity));
        } else {
          dispatch(fetchNavCommunity());
        }
      } else {
        dispatch(fetchNavCommunity());
      }
    }
    if (auth && auth.user && auth.navCommunity && !synchroNotificationFetched) {
      let collaboratorListSynchroList = getCollaboratorsTimeSheetNotification(
        auth.navCommunity.id,
        auth.token
      );
      if (collaboratorListSynchroList) {
        collaboratorListSynchroList.then((result) => {
          const data = result.data.data;
          dispatch(setSynchronisationNotification(data));
        });
      }
      setSynchroNotificationFetched(true);
    }
    if (auth && auth.user && auth.navCommunity) {
      let preferencesResult =
        auth.navCommunity.id === 4
          ? auth.user.userOrganizations.find(
              (organization) => organization.id === auth.navCommunity.id
            ).authorisation.preferences
          : {};
      setPreferences(preferencesResult);

      if (window.location.pathname === `/`) {
        if (
          loggedAs !== "MANAGER" ||
          (preferencesResult &&
            preferencesResult.allowAccessToClientList === "1")
        ) {
          navigate(`/${language}/clients`);
        } else if (
          preferencesResult &&
          preferencesResult.allowAccessToCollaboratorList === "1"
        ) {
          navigate(`/${language}/collaborators`);
        } else {
          navigate(`/${language}/collaborators`);
        }
      }
    }
    if (
      auth &&
      auth.navCommunity &&
      auth.navCommunity.id &&
      auth.user &&
      auth.user.userOrganizations.length > 0
    ) {
      let orgs = auth.user.userOrganizations.filter((org) => {
        return parseInt(org.id) === parseInt(auth.navCommunity.id);
      });
      let navCommunity = null;
      navCommunity = orgs[0] || null;
      // if (!organizationIsSet) {
      setNavCommunity(navCommunity);
      if (navCommunity) {
        setAuthLoggedAs(navCommunity.authorisation.role);
        dispatch(setAuthLoggedAs(navCommunity.authorisation.role));
        if (navCommunity.authorisation.role === "MANAGER")
          dispatch(setAuthSpaceAs("MANAGER"));
      } else {
        dispatch(setAuthSpaceAs("COLLABORATOR"));
      }
      // setOrganizationIsSet(true);
      // }
    }
  }, [auth.user, auth.navCommunity]);

  useEffect(() => {
    if (auth.user && !loggedAs) {
      navigate(`/${language}`);
    } else if (window.location.pathname === `/${language}`) {
      navigate(`/${language}/clients`);
    }
    let allowedNotifications = [];
    if (
      (preferences && preferences.activateCalendarNotifications) ||
      loggedAs === "COLLABORATOR"
    ) {
      allowedNotifications.push("COLLABORATOR_ABSENCE");
    }
    if (
      (preferences && preferences.activateCarteNotifications) ||
      loggedAs === "COLLABORATOR"
    ) {
      allowedNotifications.push("CORRECTION_CARD");
    }
    if (
      (preferences &&
        (preferences.activateCalendarNotifications ||
          preferences.activateCarteNotifications)) ||
      loggedAs === "COLLABORATOR"
    ) {
      let managerNotification = getManagerNotification(
        auth.user.uid,
        auth.navCommunity.id,
        auth.token,
        loggedAs,
        allowedNotifications
      );
      if (managerNotification) {
        managerNotification.then((result) => {
          const data = result.data.data;
          const pendingData = data.data.filter(
            (not) => not.status === "PENDING"
          );
          dispatch(
            setManagerNotification({
              ...data,
              countPending: pendingData
                ? pendingData.length +
                  (auth.managerNotification
                    ? auth.managerNotification.countPending
                    : 0)
                : 0,
            })
          );
        });
      }
    }
  }, [auth.user, loggedAs, language]);

  let subMenu = [];
  if (!loggedAs) {
    subMenu = [{ community: false }];
  } else if (loggedAs === "MANAGER" && spaceAs === "MANAGER") {
    subMenu = [
      {
        title: _("clients"),
        url: `/${language}/clients`,
        iconUrl:
          "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/team/folder.svg",
        community: false,
        hasAccess: preferences && preferences.allowAccessToClientList === "1",
      },
      {
        title: _("collaborators"),
        url: `/${language}/collaborators`,
        iconUrl:
          "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/authors.svg",
        className: "hide-for-small-only",
        hasAccess: preferences && preferences.allowAccessToCollaboratorList === "1",
      },
      {
        title: _("PRESTA-TS"),
        url: `/${language}/time-sheet`,
        iconUrl:
          "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/categories.svg",
        className: "hide-for-small-only",
        hasAccess: preferences && preferences.allowAccessPrestaTS === "1",
      },
      {
        title: _("Financial"),
        iconUrl:
          "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/articles.svg",
        className: "hide-for-small-only",
        hasAccess: preferences && preferences.allowAccessFinancial === "1",
        subMenu: [
          {
            title: _("Financial Sheet"),
            url: `/${language}/financial-sheet`,
            hasAccess: preferences && preferences.allowAccessFinancialSheet === "1",
          },
          {
            title: _("Forfait"),
            url: `/${language}/forfait`,
            hasAccess: preferences && preferences.allowAccessForfait === "1",
          },
          {
            title: _("Croissance"),
            url: `/${language}/croissance`,
            hasAccess: preferences && preferences.allowAccessForfait === "1",
          },
        ],
      },
      {
        title: _("dynamicTable"),
        url: `/${language}/dynamic-table`,
        iconUrl:
          "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/articles.svg",
        className: "hide-for-small-only",
        hasAccess: preferences && preferences.allowAccessDynamicTab === "1",
      },
      {
        title: _("reporting"),
        url: `/${language}/reporting`,
        iconUrl:
          "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/team/briefcase.svg",
        className: "hide-for-small-only",
        hasAccess: preferences && preferences.allowAccessReporting === "1"
      },
      {
        title: _("capacity planing"),
        url: `/${language}/capacity-planing`,
        iconUrl:
          "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/categories.svg",
        className: "hide-for-small-only",
        hasAccess: preferences && preferences.allowAccessCapacityPlanning=== "1"
      },
    ];
    // if (preferences && preferences.allowAccessToCollaboratorList === "1") {
    //   subMenu.unshift({
    //     title: _("collaborators"),
    //     url: `/${language}/collaborators`,
    //     iconUrl:
    //       "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/authors.svg",
    //     className: "hide-for-small-only",
    //     hasAccess: preferences && preferences.allowAccessToCollaboratorList === "1",
    //   });
    // }
    // if (preferences && preferences.allowAccessToClientList === "1") {
    //   subMenu.unshift({
    //     title: _("clients"),
    //     url: `/${language}/clients`,
    //     iconUrl:
    //       "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/team/folder.svg",
    //     community: false,
    //     hasAccess: preferences && preferences.allowAccessToClientList === "1",
    //   });
    // }
  } else {
    subMenu = [
      {
        title: _("clients"),
        url: `/${language}/clients`,
        iconUrl:
          "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/team/folder.svg",
        community: false,
        hasAccess: preferences && preferences.allowAccessToClientList === "1",
      },
      {
        title: _("My space"),
        url: `/${language}/user-space`,
        iconUrl:
          "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/team/folder.svg",
        community: false,
        hasAccess: true,
      },
      {
        title: _("PRESTA-TS"),
        url: `/${language}/collaborator/time-sheet`,
        iconUrl:
          "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/team/folder.svg",
        community: false,
        hasAccess: true,
      },
    ];
    // if (preferences && preferences.allowAccessToClientList === "1") {
    //   subMenu.unshift({
    //     title: _("clients"),
    //     url: `/${language}/clients`,
    //     iconUrl:
    //       "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/team/folder.svg",
    //     community: false,
    //     hasAccess: true,
    //   });
    // }
  }
  if (!showHeader && loggedAs === "MANAGER" && spaceAs === "MANAGER") {
    subMenu.push({
      title: _("settings"),
      iconUrl:
        "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/articles.svg",
      // iconUrl:
      //   "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/authors.svg",
      hasAccess: preferences && preferences.allowAccessSetting === "1",
      subMenu: [
        {
          title: _("categories"),
          url: `/${language}/category-settings`,
          // iconUrl:
          //   "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/team/folder.svg",
          community: false,
          hasAccess: preferences && preferences.allowAccessCategorySetting === "1",
        },
        {
          title: _("task types"),
          url: `/${language}/task-types`,
          // iconUrl:
          //   "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/articles.svg",
          className: "hide-for-small-only",
          hasAccess: preferences && preferences.allowAccessTaskTypeSetting === "1",
        },
        {
          title: _("collaborateurs"),
          url: `/${language}/collaborators-settings`,
          // iconUrl:
          //   "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/articles.svg",
          className: "hide-for-small-only",
          hasAccess: preferences && preferences.allowAccessCollaboratorSetting === "1",
        },
        {
          title: _("calendrier"),
          url: `/${language}/calendar-settings`,
          // iconUrl:
          //   "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/articles.svg",
          className: "hide-for-small-only",
          hasAccess: preferences && preferences.allowAccessCalendarSetting === "1",
        },
        {
          title: _("paramétres"),
          url: `/${language}/parameter-settings`,
          // iconUrl:
          //   "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/articles.svg",
          className: "hide-for-small-only",
          hasAccess: preferences && preferences.allowAccessParameterSetting === "1",
        },
        {
          title: _("utilisateurs"),
          url: `/${language}/authorizations`,
          // iconUrl:
          //   "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/articles.svg",
          className: "hide-for-small-only",
          hasAccess: preferences && preferences.allowAccessUserSetting === "1",
        },
        {
          title: _("modeles"),
          url: `/${language}/modeles-settings`,
          // iconUrl:
          //   "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/articles.svg",
          className: "hide-for-small-only",
          hasAccess: preferences && preferences.allowAccessModelSetting === "1",
        },
      ],
    });
  }
  return (
    <div className="off-canvas-wrapper">
      {auth.user && (
        <div className="off-canvas-content">
          {auth && auth.navCommunity && showHeader && <Menu token={auth.token} />}
          {auth.user &&
            (loggedAs === "MANAGER" || loggedAs === "COLLABORATOR") && (
              <div>
                {/* <NotificationContainer /> */}
                <div
                  className={`flex-container flex-child-auto
            flex-dir-column
          `}>
                  <SubMenu
                    RouterLink={Link}
                    lng={language}
                    hideVertical={!showHeader}
                    menu={subMenu}
                  />
                </div>
                <Outlet />
              </div>
            )}
          {auth.user &&
            loggedAs !== "MANAGER" &&
            loggedAs !== "COLLABORATOR" && (
              <div className="no_data">
                <div className="title">
                  {_(
                    "L'application Power Team n'est pas Activée pour l'organisation actuelle."
                  )}
                </div>
                <div className="subTitle">
                  {_(
                    "Pour plus d'informations sur ce produit, merci de nous consulter."
                  )}
                </div>
              </div>
            )}
        </div>
      )}
      <div className="mask hide" />
    </div>
  );
};

export default Layout;
