import { TiArrowSortedDown } from "react-icons/ti";
import { renderTeamYears } from "../../../../utils";
import styles from './YearFilter.module.scss';
import { useEffect, useState } from "react";

export default function YearFilter({onYearChange}) {
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState([]);

  useEffect(() => {
    const values = renderTeamYears().sort((a, b) => Number(b) - Number(a));
    setYears(values);
  }, []);

  useEffect(() => {
    if (onYearChange && typeof onYearChange === 'function') {
      onYearChange(selectedYear);
    }
  }, [onYearChange, selectedYear]);

  return (
    <div
        className={`${styles.customedSelect} ${yearDivIsVisible ? styles.customedSelectOpened : ''}`}>
        <div
          className={styles.customedSelectHeader}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{selectedYear}</span>
          <div className={styles.actions}>
            <div>
              <span className={styles.action}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={styles.customedSelectBody}>
            <div className={styles.customedSelectBodyOptions}>
              {years.map((item, index) => (
                <div
                  key={index}
                  className={styles.customedSelectBodyOption}
                  onClick={() => {
                    setSelectedYear(item);
                    setYearDivIsVisible(false);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
  )
}
