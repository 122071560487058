import { TiArrowSortedDown } from "react-icons/ti";
import styles from './PaginationFilter.module.scss';
import { useEffect, useState } from "react";

export default function PaginationFilter({onValueChange, defaultValue = 5, options = [5, 10, 20, 25, 50, 100]}) {
  const [headerVisible, setHeaderVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    if (onValueChange && typeof onValueChange === 'function') {
      onValueChange(selectedValue);
    }
  }, [onValueChange, selectedValue]);

  return (
    <div
        className={`${styles.customedSelect} ${headerVisible ? styles.customedSelectOpened : ''}`}>
        <div
          className={styles.customedSelectHeader}
          onClick={() => setHeaderVisible(!headerVisible)}
        >
          <span>{selectedValue}</span>
          <div className={styles.actions}>
            <div>
              <span className={styles.action}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {headerVisible && (
          <div className={styles.customedSelectBody}>
            <div className={styles.customedSelectBodyOptions}>
              {options.map((item, index) => (
                <div
                  key={index}
                  className={styles.customedSelectBodyOption}
                  onClick={() => {
                    setSelectedValue(item);
                    setHeaderVisible(false);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
  )
}
