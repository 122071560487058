import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import _ from "../../../i18n";
import {
  getMonthShortLabelObject,
  renderDurationDisplay,
} from "../../../utils";
import { getCollaboratorContributionByCategory } from "../../../api";
import {
  setCategoryDefaultFilter,
  setSelectedStartDateFilter,
  setSelectedEndDateFilter,
} from "../../../store";

import { Modal, Tooltip } from "antd";
import { Calendar } from "primereact/calendar";
import { Carousel } from "primereact/carousel";
import { AlertCircleIcon, CalendarIcon, IconClose } from "../../common/Icons";
import SearchBox from "../../common/Filter/SearchBox";
import EncodingProgressionContent from "./EncodingProgression/EncodingProgressionContent";
import ProgressionCompensationContent from "./EncodingProgression/ProgressionCompensationContent";
import BalanceProgressionContent from "./EncodingProgression/BalanceProgressionContent";
import TVAProgressionContent from "./EncodingProgression/TVAProgressionContent";
import NonFacturableContent from "./EncodingProgression/NonFacturableContent";
import Progression6Content from "./EncodingProgression/Progression6Content";
import "./CollaboratorProductionTracking.local.scss";

const availableCategories = [
  "02",
  "04",
  "05",
  "06",
  "08",
  "14",
  "15"
];

const CollaboratorProductionTracking = ({
  id,
  year,
  lng,
  token,
  user,
  name,
  loggedAs,
  collaborator,
  organization,
}) => {
  const dispatch = useDispatch();
  const {
    categoryDefaultFilter,
    selectedStartDateFilter,
    selectedEndDateFilter,
  } = useSelector((state) => state.filters);
  //Header
  const [filter, setFilter] = useState({
    filterTab: [
      {
        id: "MONTHLY",
        label: "monthly",
        subTab: getMonthShortLabelObject(lng),
      },
      {
        id: "TERMLY",
        label: "termly",
        subTab: [
          {
            id: 1,
            label: "1er trimestre (du 01/01 au 30/03)",
            disabled: false,
            start: "01-01",
            end: "03-30",
          },
          {
            id: 2,
            label: "2ème trimestre (du 01/04 au 30/06)",
            disabled: moment().quarter() < 2 && moment().year() == year,
            start: "04-01",
            end: "06-30",
          },
          {
            id: 3,
            label: "3ème trimestre (du 01/07 au 30/09)",
            disabled: moment().quarter() < 3 && moment().year() == year,
            start: "07-01",
            end: "09-30",
          },
          {
            id: 4,
            label: "4ème trimestre (du 01/10 au 30/12)",
            disabled: moment().quarter() < 4 && moment().year() == year,
            start: "10-01",
            end: "12-31",
          },
        ],
      },
      { id: "ANNUAL", label: "annual", start: "01-01", end: "12-31" },
    ],
    category: categoryDefaultFilter,
    period: null,
    subPeriod: null,
    start: selectedStartDateFilter,
    end: selectedEndDateFilter,
  });
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState({});
  const balance_types = ["IPM", "ISOC"];
  const [balanceRefresh, setBalanceRefresh] = useState(false);
  const [toSwitch, setToSwitch] = useState(false);

  let contributionByCategoryQuery = useQuery(
    [
      "contributionByCategoryQuery",
      token,
      year,
      filter.start,
      filter.end,
      filter.period,
      filter.subPeriod,
    ],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorContributionByCategory(
            id,
            organization,
            year,
            token,
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.start
                : filter.subPeriod.start
              : filter.start
              ? moment(filter.start).format("MM-DD")
              : "01-01",

            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.end
                : filter.subPeriod.end
              : filter.end
              ? moment(filter.end).format("MM-DD")
              : "12-31"
          );

          const data = response.data.data;
          const currentCategory = data.real.filter(item => item.id == filter.category.id);

          setCategories(data);
          if (!currentCategory.length) {
            setToSwitch(true);
          }
        } catch (e) {
          console.log(e);
          setCategories({});
          return null;
        }
      }
    }
  );

  useEffect(() => {
    if (year && year !== new Date().getFullYear().toString()) {
      setFilter({
        ...filter,
        start: null,
        end: null,
        period: {
          id: "ANNUAL",
          label: "annual",
          start: "01-01",
          end: "12-31",
        },
      });
    }
  }, [year]);

  const renderFilterFrame = () => {
    return (
      <div className="filter-frame">
        <div className="principal-line">
          <div className="interval-date-frame">
            <div
              className={`date-range ${
                filter.start || filter.end ? "date-range_fill" : ""
              }`}
            >
              <CalendarIcon fill="#6D7F92" />
              <span className="p-float-label start-date">
                <Calendar
                  className={filter.start ? "calendar-fill" : ""}
                  dateFormat="dd/mm/yy"
                  inputId="start_date"
                  value={filter.start}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      start: e.value,
                      end: filter.end
                        ? filter.end
                        : moment(e.value).add(15, "days")._d,
                      period: null,
                      subPeriod: null,
                    });
                    dispatch(setSelectedStartDateFilter(e.value));
                    dispatch(
                      setSelectedEndDateFilter(
                        filter.end
                          ? filter.end
                          : moment(e.value).add(15, "days")._d
                      )
                    );
                  }}
                />
                <label htmlFor="start_date">
                  {filter.start ? "" : _("startDate")}
                </label>
              </span>
              <span className="p-float-label">
                <Calendar
                  className={filter.start ? "calendar-fill" : ""}
                  dateFormat="dd/mm/yy"
                  inputId="end_date"
                  value={filter.end}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      end: e.value,
                      start: filter.start
                        ? filter.start
                        : moment(e.value).subtract(15, "days")._d,
                      period: null,
                      subPeriod: null,
                    });
                    dispatch(
                      setSelectedStartDateFilter(
                        filter.start
                          ? filter.start
                          : moment(e.value).subtract(15, "days")._d
                      )
                    );
                    dispatch(setSelectedEndDateFilter(e.value));
                  }}
                />
                <label htmlFor="end_date">
                  {filter.end ? "" : _("endDate")}
                </label>
              </span>
              {(filter.start || filter.end) && (
                <div
                  className="date-range_action"
                  onClick={() => {
                    setFilter({
                      ...filter,
                      start: null,
                      end: null,
                      period: {
                        id: "ANNUAL",
                        label: "annual",
                        start: "01-01",
                        end: "12-31",
                      },
                    });
                    dispatch(setSelectedStartDateFilter(null));
                    dispatch(setSelectedEndDateFilter(null));
                  }}
                >
                  <IconClose fill="#E1E4E8" size={6} />
                </div>
              )}
            </div>
          </div>
          <div className="mini-tabs-frame">
            {filter.filterTab.map((item) => (
              <div
                className={
                  filter.period &&
                  item.id === filter.period.id &&
                  "mini-tabs-frame_active"
                }
                onClick={() => {
                  setFilter({
                    ...filter,
                    period: item,
                    subPeriod:
                      "MONTHLY" === item.id
                        ? item.subTab[moment().month()]
                        : "TERMLY" === item.id
                        ? item.subTab[moment().quarter() - 1]
                        : null,
                    start: null,
                    end: null,
                  });
                }}
              >
                {_(item.label)}
              </div>
            ))}
          </div>
          <SearchBox
            placeholder={`${_("search")}...`}
            onChange={(item) => setFilter({ ...filter, keyWord: item })}
            filter={filter}
          />
        </div>
        {filter.period && "ANNUAL" !== filter.period.id && (
          <div className="additional-line">
            {filter.period.subTab.map((tab) => {
              return (
                <div
                  className={`tag ${
                    filter.subPeriod.id === tab.id ? "active" : ""
                  } ${
                    ((tab.disabled &&
                      filter.period &&
                      filter.period.id === "TERMLY") ||
                      (filter.period &&
                        filter.period.id === "MONTHLY" &&
                        moment().year() == year &&
                        tab.id > moment().month() + 1)) &&
                    "disabled"
                  }`}
                  onClick={() =>
                    !tab.disabled
                      ? setFilter({
                          ...filter,
                          subPeriod: tab,
                          start: null,
                          end: null,
                        })
                      : null
                  }
                >
                  {tab.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const switchCategory = () => {
    const filteredCategories = 
      categories.real
      && categories.real.length > 0
        ? categories.real.filter((category) => availableCategories.includes(category.label.split(".")[0]))
        : [];
    return (
      <Modal
        title={<h3>{_('switchCategory')}</h3>}
        footer={null}
        open={toSwitch}
        width={700}
        onCancel={() => setToSwitch(false)}
        className="switch-category-modal-control"
      >
        <div className="switch-category-modal">
          <div className="modal-alert">
            <p>{_('switchCategoryAlertMessage')}</p>
            <strong>{filter.category.label}</strong>
          </div>
          <p>{_('switchCategoryMessage')}</p>
          <div className="category-list">
            {filteredCategories.map(category => {
              return (
                <button
                  key={category.id}
                  onClick={() => {
                    setFilter({
                      ...filter,
                      category: {
                        id: category.id,
                        label: category.label
                      }
                    });
                    dispatch(setCategoryDefaultFilter(category));
                    setToSwitch(false);
                  }}
                  className="category-button"
                >
                  {category.label}
                </button>
              );
            })}
          </div>
        </div>
      </Modal>
    )
  }

  const renderCategoryCarousel = () => {
    let total = 0;
    const filteredCategories = 
      categories.real
      && categories.real.length > 0
        ? categories.real.filter((category) => availableCategories.includes(category.label.split(".")[0]))
        : [];
    filteredCategories.forEach((category) => {
      total += parseFloat(category.duration);
    });
    return (
      <Fragment>
        {filteredCategories && filteredCategories.length > 0 && (
          <div className="production-frame_tabs">
            <Carousel
              value={filteredCategories}
              numVisible={4}
              numScroll={1}
              responsiveOptions={[]}
              itemTemplate={(category, i) => {
                return (
                  <Tooltip
                    key={`tab-${i}`}
                    placement="top"
                    title={`${parseFloat(
                      parseFloat((category.duration * 100) / total).toFixed(2)
                    )}%`}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        key={i}
                        className={`tab_badge ${
                          filter.category.id === category.id ? "tab_badge_active" : ""
                        }`}
                        onClick={() => {
                          // if (["15"].includes(category.label.split(".")[0])) {
                          //   return;
                          // }
                          setFilter({ ...filter, category });
                          dispatch(setCategoryDefaultFilter(category));
                          setBalanceRefresh(true);
                        }}
                      >
                        <div>
                          {category.label.substr(0, 24)}
                          {category.label.length > 25 ? "." : ""}
                        </div>
                      </div>
                      <div
                        className={`tab_tag ${
                          filter.category.id === category.id
                            ? "tab_tag_active"
                            : ""
                        }`}
                      >
                        {renderDurationDisplay(category.duration * 60)}
                      </div>
                    </div>
                  </Tooltip>
                );
              }}
            />
            <div className="total">
              <div>
                <div>{_("total")}</div>
                <div
                  style={{
                    display: "flex",
                    margin: "5px 0px 3px 5px",
                    backgroundColor: "transparent",
                  }}
                >
                  <div
                    className="item_progress_container"
                    style={{ width: 80 }}
                  >
                    <div
                      className="item_progress_bar"
                      style={{
                        width: `${
                          categories.theoric > 0
                            ? (total * 100) / categories.theoric
                            : 0
                        }%`,
                        background: "#06D9B1",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div>{renderDurationDisplay(total * 60)}</div>
                <Tooltip
                  key={`tab-20}`}
                  placement="bottom"
                  title={`${_("toAttributTheoricaly")} : ${parseFloat(
                    categories.theoric
                  )}h`}
                >
                  <div>
                    <AlertCircleIcon />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    )
  }

  const renderCategoryProductionTraking = () => {
    switch (filter.category.label.split(".")[0]) {
      case "04":
        return (
          <EncodingProgressionContent
            id={id}
            token={token}
            lng={lng}
            year={year}
            loggedAs={loggedAs}
            collaborator={collaborator}
            organization={organization}
            filter={filter}
            user={user}
          />
        );
      case "05":
        return (
          <TVAProgressionContent
            id={id}
            token={token}
            lng={lng}
            year={year}
            loggedAs={loggedAs}
            zoneId={collaborator.zoneId}
            organization={organization}
            filter={filter}
            user={user}
          />
        );
      case "06":
        return (
          <Progression6Content
            id={id}
            token={token}
            lng={lng}
            year={year}
            loggedAs={loggedAs}
            collaborator={collaborator}
            organization={organization}
            filter={filter}
            user={user}
          />
        );
      case "08":
        return (
          <BalanceProgressionContent
            id={id}
            token={token}
            lng={lng}
            year={year}
            loggedAs={loggedAs}
            zoneId={collaborator.zoneId}
            organization={organization}
            filter={filter}
            user={user}
          />
        );
      case "14":
      case "02":
        return (
          <ProgressionCompensationContent
            id={id}
            token={token}
            lng={lng}
            name={name}
            year={year}
            loggedAs={loggedAs}
            collaborator={collaborator}
            organization={organization}
            filter={filter}
            user={user}
          />
        );
        case "15":
          return (
            <NonFacturableContent
              id={id}
              token={token}
              lng={lng}
              name={name}
              year={year}
              loggedAs={loggedAs}
              collaborator={collaborator}
              organization={organization}
              filter={filter}
              user={user}
            />
          );
      default:
        {switchCategory()}
    }
  };


  const renderErrorMessage = () => {
    switch (error.code) {
      case 170047:
        return (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("collaboratorNotConfigured")}</div>
          </div>
        );
      case 170148:
        return (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("calendarNotConfigured")}</div>
          </div>
        );

      default:
        return (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("serverError")}</div>
          </div>
        );
    }
  };
  return (
    <Fragment>
      {switchCategory()}
      {renderFilterFrame()}
      {renderCategoryCarousel()}
      {contributionByCategoryQuery.isFetched && error
        ? renderErrorMessage()
        : (
          <div className="production-frame">
            {renderCategoryProductionTraking()}
          </div>
        )}
    </Fragment>
  );
};

export default CollaboratorProductionTracking;
